<template>
    <div class="flex justify-center p-2 bg-white bg-opacity-80 rounded-bl-md fixed top-0 right-0">
        <button @click="signout" class="flex flow-row justify-center bg-gradient-to-r from-red-600 to-red-500 hover:from-red-500 hover:to-red-400 text-white py-2 px-4 rounded">
            Se déconnecter 
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
        </button>
    </div>
</template>

<script>
import {supabase} from '../supabase';
export default {
    name: 'Logout',
    methods: {
        signout() {
            supabase.auth.signOut()
            .then(()=> this.$router.go('/'))
        },
    }
}
</script>