<template>
    <div class="flex flex-col justify-center items-center min-h-screen h-full bg-fixed bg-profile bg-cover bg-center">
        <logout />
        <navbar />
        <div class=" w-3/4 h-3/4 bg-white bg-opacity-80 rounded-md p-4 text-black font-semibold">
            <h1 class="text-xl text-center">{{profil}},</h1>
            <p class="text-center mt-4">Ici tu peux modifier ton nom afin que les autres membres puissent te reconnaitre</p>
            <form @submit.prevent="updateProfil(profileToEdit)" class="flex flex-col justify-center items-center">
                <div>
                    <label for="input-name">Nom</label>
                    <input type="text" v-model="profileToEdit" class="w-full">
                </div>
                <div class="mt-4">
                    <button class="bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400 text-white py-2 px-4 rounded">Editer</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'
import { mapState, mapActions } from 'vuex';
import Logout from '../components/Logout.vue';
export default {
    name: 'profil',
    components: {Navbar, Logout},
    data() {
        return {
            profileToEdit: '',
        }
    },
    beforeMount () {
        this.profileToEdit = this.profil
    },
    computed: {
        ...mapState(['profil'])
    },
    methods: {
        ...mapActions(['updateProfil'])
    },
    
}
</script>

<style>

</style>